import Vue from 'vue'
import App from './App.vue'
import { Auth0Plugin } from "./auth"
import { Button, Image } from 'buefy'
import 'buefy/dist/buefy.css'
import router from './router'

const domain = process.env.VUE_APP_AUTH_DOMAIN;
const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
const audience = process.env.VUE_APP_AUDIENCE

Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
  });

Vue.use(Button)
Vue.use(Image)



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
