import Vue from 'vue'
import VueRouter from 'vue-router'
import {authGuard} from "@/auth/authGuard";
import Base from "@/components/Base";

Vue.use(VueRouter)

const routes = [
  {
    path: '/*',
    name: 'Base',
    component: Base,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
