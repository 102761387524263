<template>
    <section class="section is-large">
        <div class="container">
            <div class="columns">
                <div class="column"></div>
                <div class="column">
                    <b-image
                            :src="require('@/assets/logo.png')"
                            alt="DVJ Logo"
                    ></b-image>
                </div>
                <div class="column"></div>
            </div>
            <div class="columns">
                <div class="column"></div>
                <div class="column">
                    <DvjButton :url="dashboard_url">Dashboards</DvjButton> <br />
                </div>
                <div class="column">
                    <DvjButton :url="insights_portal">Insights Portal</DvjButton>
                </div>
                <div class="column"></div>
            </div>
        </div>
    </section>
</template>

<script>
import DvjButton from './DvjButton.vue'

export default {
    name: 'Base',
    components: {
        DvjButton
    },
    data: () => {
        return {
            dashboard_url: "https://dashboard.dvj-insights.com/",
            insights_portal: "https://portal.dvj-insights.com/projects",
        }
    },
}
</script>

<style>
</style>
