<template>
    <b-button @click="Navigate()" expanded type="is-dark">
        <b><slot></slot></b>
    </b-button>
</template>

<script>
export default {
  name: 'DvjButton',
    props: ['url'],
    methods: {
      Navigate(){
          window.open(this.url, '_blank');
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button.is-dark{
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-size: 20px;
    background-color: #000000;
}
</style>
